import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    interpolation: {
      escapeValue: false,
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'], // Cache the chosen language in a cookie for persistence
    },
    fallbackLng: 'EN',
    resources: {
      EN: {
        translation: {
          are_you_sure_stop_conversation: "Are you sure you want to stop the conversation?",
          no_video: "No video",
          enter_session_id: "Enter Session ID",
          session_id: "Session ID",
          enter_password_for_new_session: "Enter Password for New Session",
          enter_password: "Enter Password",
          create_session: "Create Session",
          session_created: "Session created with ID: {{sessionId}}",
          next: "Next",
          select_language: "Select Language",
          select_language_placeholder: "Choose a language",
          english: "English",
          dutch: "Dutch",
          french: "French",
          agree_terms: "I agree to the terms and conditions",
          back: "Back",
          start_interview: "Start Interview",
          ai_interview: "AI Interview",
          waiting_for_ai: "Waiting for AI...",
          start_recording: "Start Recording",
          stop_recording: "Stop Recording",
          stop_conversation: "Stop Conversation",
          start_conversation: "Start Conversation",
          conversation_completed: "Conversation completed! Download the summary here.",
          microphone_camera_setup: "Microphone and Camera Setup",
          select_audio_device: "Select Audio Device",
          select_audio_device_placeholder: "Choose an audio device",
          select_video_device: "Select Video Device",
          select_video_device_placeholder: "Choose a video device",
          turn_off_camera: "Turn Off Camera",
          turn_on_camera: "Turn On Camera",
          turn_off_microphone: "Turn Off Microphone",
          turn_on_microphone: "Turn On Microphone",
        },
      },
      NL: {
        translation: {
          are_you_sure_stop_conversation: "Weet je zeker dat je het gesprek wilt stoppen?",
          no_video: "Geen video",
          enter_session_id: "Voer sessie-ID in",
          session_id: "Sessie-ID",
          enter_password_for_new_session: "Voer wachtwoord voor nieuwe sessie in",
          enter_password: "Voer wachtwoord in",
          create_session: "Sessie aanmaken",
          session_created: "Sessie aangemaakt met ID: {{sessionId}}",
          next: "Volgende",
          select_language: "Selecteer taal",
          select_language_placeholder: "Kies een taal",
          english: "Engels",
          dutch: "Nederlands",
          french: "Frans",
          agree_terms: "Ik ga akkoord met de voorwaarden",
          back: "Terug",
          start_interview: "Start Interview",
          ai_interview: "AI Interview",
          waiting_for_ai: "Wacht op de AI...",
          start_recording: "Start opname",
          stop_recording: "Stop opname",
          stop_conversation: "Stop Gesprek",
          start_conversation: "Start Gesprek",
          conversation_completed: "Gesprek voltooid! Download de samenvatting hier.",
          microphone_camera_setup: "Instellingen voor microfoon en camera",
          select_audio_device: "Selecteer audio-apparaat",
          select_audio_device_placeholder: "Kies een audio-apparaat",
          select_video_device: "Selecteer video-apparaat",
          select_video_device_placeholder: "Kies een video-apparaat",
          turn_off_camera: "Camera uitschakelen",
          turn_on_camera: "Camera inschakelen",
          turn_off_microphone: "Microfoon uitschakelen",
          turn_on_microphone: "Microfoon inschakelen",
        },
      },
      FR: {
        translation: {
          are_you_sure_stop_conversation: "Êtes-vous sûr de vouloir arrêter la conversation ?",
          no_video: "Pas de vidéo",
          enter_session_id: "Entrez l'ID de session",
          session_id: "ID de session",
          enter_password_for_new_session: "Entrez le mot de passe pour une nouvelle session",
          enter_password: "Entrez le mot de passe",
          create_session: "Créer une session",
          session_created: "Session créée avec l'ID : {{sessionId}}",
          next: "Suivant",
          select_language: "Choisir la langue",
          select_language_placeholder: "Choisissez une langue",
          english: "Anglais",
          dutch: "Néerlandais",
          french: "Français",
          agree_terms: "J'accepte les termes et conditions",
          back: "Retour",
          start_interview: "Démarrer l'entretien",
          ai_interview: "Entretien AI",
          waiting_for_ai: "En attente de l'AI...",
          start_recording: "Commencer l'enregistrement",
          stop_recording: "Arrêter l'enregistrement",
          stop_conversation: "Arrêter la conversation",
          start_conversation: "Démarrer la conversation",
          conversation_completed: "Entretien terminé ! Téléchargez le résumé ici.",
          microphone_camera_setup: "Configuration du microphone et de la caméra",
          select_audio_device: "Sélectionnez le périphérique audio",
          select_audio_device_placeholder: "Choisissez un périphérique audio",
          select_video_device: "Sélectionnez le périphérique vidéo",
          select_video_device_placeholder: "Choisissez un périphérique vidéo",
          turn_off_camera: "Désactiver la caméra",
          turn_on_camera: "Activer la caméra",
          turn_off_microphone: "Désactiver le microphone",
          turn_on_microphone: "Activer le microphone",
        },
      },
    },
  });

export default i18n;
