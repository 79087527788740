import React, { useState, useEffect } from 'react';
import { VStack, Text, Button, Select, Checkbox, Box, Flex, HStack, IconButton, ChakraProvider, Switch } from '@chakra-ui/react';
import { FaVideo } from 'react-icons/fa';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import { ReactMediaRecorder } from 'react-media-recorder';
import axios from 'axios';
import './i18n';
import { useTranslation } from 'react-i18next';


function App() {
  const [step, setStep] = useState(0); // Tracks the current step in the UI
  const [sessionId, setSessionId] = useState(''); // Session ID for the interview
  const [password, setPassword] = useState(''); // Password for creating a new session
  const [language, setLanguage] = useState('EN'); // Language selection
  const [cameraOn, setCameraOn] = useState(false); // Toggles camera state
  const [microphoneOn, setMicrophoneOn] = useState(false); // Toggles microphone state
  const [conversationStarted, setConversationStarted] = useState(false); // Tracks if the conversation has started
  const [aiResponse, setAiResponse] = useState(null); // Stores AI's question/response
  const [agreedToTerms, setAgreedToTerms] = useState(false); // Terms acceptance
  const [sessionIdValid, setSessionIdValid] = useState(false); // Validates session ID
  const [sessionIdError, setSessionIdError] = useState(''); // Stores session validation error
  const [isProcessing, setIsProcessing] = useState(false); // Processing state for audio send
  const [conversationFinished, setConversationFinished] = useState(false); // Tracks if conversation is finished
  const [summaryFileUrl, setSummaryFileUrl] = useState(''); // URL of the conversation summary
  const [cameraStream, setCameraStream] = useState(null); // Camera stream state
  const [videoDevices, setVideoDevices] = useState([]); // List of video input devices
  const [audioDevices, setAudioDevices] = useState([]); // List of audio input devices
  const [isRecording, setIsRecording] = useState(false); // Tracks if recording is active
  const [isPlayingAudio, setIsPlayingAudio] = useState(false); // Tracks if AI's audio response is playing
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null); // Selected video input device
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null); // Selected audio input device
  const [microphoneStream, setMicrophoneStream] = useState(null);
  const [videoRef, setVideoRef] = useState(null);
  const {t, i18n} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    const initializeSession = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlSessionId = urlParams.get('session_id');
      const storedSessionId = urlSessionId || localStorage.getItem('sessionId');
      console.log('Retrieved session ID:', storedSessionId); 

      setSessionId(storedSessionId || '');

      if (storedSessionId) {
        try {
          const response = await axios.get('https://system.beasy.ai/api/validate_session', { params: { session_id: storedSessionId } });
          console.log('Session validation response:', response.data);  

          if (response.data.valid) {
            setSessionIdValid(true);
            setSessionIdError('');
            localStorage.setItem('sessionId', storedSessionId);

            const languageFromCheck = await checkLanguage(storedSessionId);
            const consentFromCheck = await checkConsent(storedSessionId);

            if (languageFromCheck && consentFromCheck) {
              setLanguage(languageFromCheck);
              i18n.changeLanguage(languageFromCheck);
              setAgreedToTerms(true);
              setStep(2);
            } else {
              const savedLanguage = languageFromCheck || localStorage.getItem('selectedLanguage') || 'EN';
              setLanguage(savedLanguage);
              i18n.changeLanguage(savedLanguage);
              localStorage.setItem('selectedLanguage', savedLanguage);
              setStep(1); // Proceed to language selection and consent if not yet done
            }
          } else {
            setSessionIdError('Sessie-ID niet gevonden in de database.');
            setSessionIdValid(false);
          }
        } catch (error) {
          console.error('Error validating session:', error);
          setSessionIdError('Fout bij het valideren van de sessie.');
        }
      } else {
        setStep(0); // Stay on session entry if no session ID found
      }
    };

    initializeSession();
  }, [i18n]);


  useEffect(() => {
    return () => {
      if (step !== 3) {
        if (cameraStream) {
          cameraStream.getTracks().forEach((track) => track.stop());
          setCameraStream(null);
        }
        if (microphoneStream) {
          microphoneStream.getTracks().forEach((track) => track.stop());
          setMicrophoneStream(null);
        }
        setCameraOn(false);
        setMicrophoneOn(false);
      }
    };
  }, [step]);

  useEffect(() => {
    const requestMediaDevices = () => {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
          const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
          setVideoDevices(videoInputDevices);
          setAudioDevices(audioInputDevices);

          if (!selectedVideoDevice && videoInputDevices.length > 0) {
            setSelectedVideoDevice(videoInputDevices[0].deviceId);
          }
        })
        .catch((err) => console.error('Error accessing media devices:', err));
    };

    requestMediaDevices();
  }, []);

  const toggleCamera = async (isChecked) => {
    if (isChecked) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: selectedVideoDevice ? { deviceId: { exact: selectedVideoDevice } } : true,
        });

        setCameraStream(stream);
        setCameraOn(true);
        const videoElement = document.getElementById('camera-preview');
        if (videoElement) {
          videoElement.srcObject = stream;
        }
      } catch (err) {
        console.error('Error accessing camera:', err);
        setCameraOn(false);
      }
    } else {
      if (cameraStream) {
        cameraStream.getTracks().forEach((track) => track.stop());
      }
      setCameraStream(null);
      setCameraOn(false);
    }
  };

  const toggleMicrophone = (deviceId = selectedAudioDevice) => {
    if (microphoneOn && microphoneStream) {
      microphoneStream.getTracks().forEach(track => track.stop());
      setMicrophoneStream(null);
    } else {
      navigator.mediaDevices.getUserMedia({ audio: { deviceId } })
        .then((stream) => {
          setMicrophoneStream(stream);
          setSelectedAudioDevice(deviceId);
        })
        .catch((err) => console.error('Error starting microphone:', err));
    }
    setMicrophoneOn(!microphoneOn);
  };

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  };

  const handleVideoDeviceChange = (e) => {
    const deviceId = e.target.value;
    setSelectedVideoDevice(deviceId);

    if (cameraOn) {
      toggleCamera(false);
      toggleCamera(true);
    }
  };

  // Plays the AI's audio response
  const playAudio = (audioUrl) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(audioUrl);
      setIsPlayingAudio(true);
      audio.play().then(resolve).catch(reject);
      audio.onended = () => {
        setIsPlayingAudio(false);
        resolve();
      };
    });
  };

  // Validates the session ID entered by the user
  const validateSessionId = async () => {
    try {
      const response = await axios.get('https://system.beasy.ai/api/validate_session', { params: { session_id: sessionId } });
      if (response.data.valid) {
        setSessionIdValid(true);
        setSessionIdError('');
        setStep(1);
      } else {
        setSessionIdError('Sessie-ID niet gevonden in de database.');
        setSessionIdValid(false);
      }
    } catch (error) {
      console.error('Error validating session:', error);
      setSessionIdError('Fout bij het valideren van de sessie.');
    }
  };

  // Starts the conversation with the AI, fetching the first question
  const startConversation = async () => {
    try {
      if (!sessionId) {
        console.error("Session ID is required to start conversation");
        return;
      }
      const response = await axios.get('https://system.beasy.ai/api/initial_question', { params: { session_id: sessionId, session_language: language } });
      const { question, speech_file_url } = response.data;
      setAiResponse(question);
      setConversationStarted(true);

      if (speech_file_url) await playAudio(`https://system.beasy.ai${speech_file_url}`);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  // Stops the conversation and fetches the summary
  const stopConversation = async () => {
    try {
      const response = await axios.post('https://system.beasy.ai/api/stop_conversation', { session_id: sessionId });
      const { summary_file } = response.data;
      setConversationFinished(true);
      setSummaryFileUrl(`https://system.beasy.ai/${summary_file}`);
    } catch (error) {
      console.error('Error stopping the conversation:', error);
    }
  };

  // Sends the recorded audio to the backend for transcription and AI response
  const handleSendAudio = async (audioBlob) => {
    if (!sessionId) {
      console.error('Session ID is not set');
      return;
    }

    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio.wav');
    formData.append('session_id', sessionId);

    try {
      setIsProcessing(true);
      const response = await axios.post('https://system.beasy.ai/api/conversation', formData, { headers: { 'Content-Type': 'multipart/form-data' }, params: { session_id: sessionId, session_language: language } });
      const { transcribed_text, gpt_response, speech_file_url } = response.data;
      setAiResponse(gpt_response);

      if (speech_file_url) await playAudio(`https://system.beasy.ai${speech_file_url}`);
    } catch (error) {
      console.error('Error sending audio to the server:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const updateLanguageInDb = async () => {
    try {
      const sessionId = localStorage.getItem('sessionId'); // Ensure sessionId is saved in localStorage
      const selectedLanguage = localStorage.getItem('selectedLanguage') || 'EN'; // Default to 'EN' if no language selected
      
      // Ensure sessionId and language are both available
      if (!sessionId) {
        throw new Error('Session ID is missing');
      }
  
      const response = await fetch('https://system.beasy.ai/api/update_language', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_id: sessionId,  // Include session ID
          language: selectedLanguage, // Include the selected language
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update language');
      }
  
      const data = await response.json();
      console.log('Language updated:', data);
    } catch (error) {
      console.error('Error updating language:', error);
    }
  };
  
  const updateConsentStatusInDb = async () => {
    try {
      const sessionId = localStorage.getItem('sessionId'); // Ensure sessionId is saved in localStorage
      const consentStatus = agreedToTerms
      
      // Ensure sessionId and language are both available
      if (!sessionId) {
        throw new Error('Session ID is missing');
      }
  
      const response = await fetch('https://system.beasy.ai/api/update_consent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_id: sessionId,  // Include session ID
          consent_given: consentStatus, 
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update consent');
      }
  
      const data = await response.json();
      console.log('Consent updated:', data);
    } catch (error) {
      console.error('Error updating consent:', error);
    }
  };

  const checkLanguage = async (sessionId) => {
    try {
      const response = await axios.get('https://system.beasy.ai/api/check_language', { params: { session_id: sessionId } });
      return response.data.language;
    } catch (error) {
      console.error('Error checking language:', error);
      return null;
    }
  };
  
  const checkConsent = async (sessionId) => {
    try {
      const response = await axios.get('https://system.beasy.ai/api/check_consent', { params: { session_id: sessionId } });
      return response.data.consent;
    } catch (error) {
      console.error('Error checking consent:', error);
      return null;
    }
  };

// Renders the session ID entry screen
const renderSessionIdStep = () => (
  <VStack spacing={4}>
    <Text fontSize="lg" color="white">{t('enter_session_id')}</Text>
    <input
      type="text"
      value={sessionId}
      onChange={(e) => setSessionId(e.target.value)}
      placeholder={t('session_id')}
      style={{ padding: '10px', fontSize: '16px', backgroundColor: 'black', color: 'white' }}
    />
    {sessionId && sessionIdValid && (
      <Text color="green.400">{t('session_created', { sessionId })}</Text>
    )}
    {sessionIdError && <Text color="red.400">{sessionIdError}</Text>}
    <Button colorScheme="green" onClick={validateSessionId} isDisabled={!sessionId}>
      {t('next')}
    </Button>
  </VStack>
);

// Language selection and terms agreement
const renderLanguageSelectionStep = () => (
  <VStack spacing={4}>
    <IconButton
      aria-label={t('back')}
      icon={<ArrowBackIcon />}
      onClick={() => setStep(step - 1)}
      variant="ghost"
      colorScheme="gray"
      position="absolute"
      top="10px"
      left="10px"
    />
    <Text fontSize="lg" color="white">{t('select_language')}</Text>
    <Select
      value={language}
      onChange={handleLanguageChange}
      placeholder={t('select_language_placeholder')}
      sx={{
        padding: '10px',
        fontSize: '16px',
        backgroundColor: 'black',  // same as options
        color: 'white',  // text color for options and selected value
        '&::placeholder': {
          color: 'white',  // placeholder color
        },
        option: {
          backgroundColor: '#4A5568', // same as options
          color: 'white', // text color for options
        }
      }}
    >
      <option value="EN">{t('english')}</option>
      <option value="NL">{t('dutch')}</option>
      <option value="FR">{t('french')}</option>
    </Select>
    <Checkbox
      isChecked={agreedToTerms}
      color="white"
      onChange={(e) => setAgreedToTerms(e.target.checked)}
      colorScheme="blue"
    >
      {t('agree_terms')}
    </Checkbox>
    <Button
      colorScheme="blue"
      onClick={() => {
        // Call the method to update language in DB before going to the next step
        updateLanguageInDb();
        updateConsentStatusInDb();
        setStep(2); // Proceed to the next step
      }}
      isDisabled={!agreedToTerms || !language}
    >
      {t('next')}
    </Button>
  </VStack>
);

// Step 2: Microphone and camera settings
const renderMediaSettingsStep = () => (
  <VStack spacing={4}>
    <IconButton
      aria-label="Back"
      icon={<ArrowBackIcon />}
      onClick={() => setStep(step - 1)}
      variant="ghost"
      colorScheme="gray"
      position="absolute"
      top="10px"
      left="10px"
    />
    <Text fontSize="lg" color="white">{t('microphone_camera_setup')}</Text>

    <Text color="white">{t('select_audio_device')}</Text>
    <Select
      value={selectedAudioDevice || ""}
      onChange={(e) => toggleMicrophone(e.target.value)}
      style={{
        padding: '10px',
        fontSize: '16px',
        backgroundColor: 'black',  // Consistent background color
        color: 'white',               // Text color for the select input
        borderRadius: 'md',           // Optional: add border radius for rounded edges
      }}
    >
      {audioDevices.map((device) => (
        <option
          key={device.deviceId}
          value={device.deviceId}
          style={{
            backgroundColor: '#4A5568',  // Same background color as Select
            color: 'white',              // Text color for the option
          }}
        >
          {device.label}
        </option>
      ))}
    </Select>

    <Text color="white">{t('select_video_device')}</Text>

    <Select
      value={selectedVideoDevice || "no-video"} // Set "no-video" as default option
      onChange={handleVideoDeviceChange}
      style={{
        padding: '10px',
        fontSize: '16px',
        backgroundColor: 'black',  // Consistent background color
        color: 'white',               // Text color for the select input
        borderRadius: 'md',           // Optional: add border radius for rounded edges
      }}
    >
      <option
        value="no-video"
        style={{
          backgroundColor: '#4A5568',  // Same background color as Select
          color: 'white',              // Text color for the option
        }}
      >
        {t('no_video')}
      </option>
      {videoDevices.map((device) => (
        <option
          key={device.deviceId}
          value={device.deviceId}
          style={{
            backgroundColor: '#4A5568',  // Same background color as Select
            color: 'white',              // Text color for the option
          }}
        >
          {device.label}
        </option>
      ))}
    </Select>
    {cameraOn && cameraStream && (
      <Box borderRadius="md" overflow="hidden" mt={4}>
        <video
          style={{ maxWidth: '100%', maxHeight: '300px' }}
          ref={(videoElement) => {
            if (videoElement) {
              videoElement.srcObject = cameraStream;
            }
          }}
          autoPlay
          playsInline
        />
      </Box>
    )}

    <HStack spacing={4} mt={6}>
      <Button
        colorScheme="blue"
        onClick={() => setStep(3)}
      >
        Start Interview
      </Button>
    </HStack>
  </VStack>
);

// Step 3: Conversation view
const renderConversationStep = () => (
  <VStack spacing={4}>
      {/* Back Button */}
      <IconButton
        aria-label="Back"
        icon={<ArrowBackIcon />}
        onClick={() => setStep(step - 1)}
        variant="ghost"
        colorScheme="gray"
        position="absolute"
        top="10px"
        left="10px"
      />

      {/* Close Button for Conversation Stop */}
      <IconButton
        aria-label="Stop Conversation"
        icon={<CloseIcon />}
        onClick={onOpen} // Open confirmation modal
        variant="ghost"
        colorScheme="gray"
        position="absolute"
        top="10px"
        right="10px"
      />

      {/* Camera Toggle with Icon and Slider */}
      {selectedVideoDevice !== "no-video" && (
        <HStack w="100%" alignItems="center">
          <FaVideo color="white" size={20} />
          <Switch
            isChecked={cameraOn}
            onChange={(e) => toggleCamera(e.target.checked)}
            colorScheme="blue"
          />
        </HStack>
      )}

      {cameraOn && cameraStream && (
        <Box borderRadius="md" overflow="hidden" mt={4}>
          <video
            style={{ maxWidth: '100%', maxHeight: '300px' }}
            ref={(videoElement) => {
              if (videoElement) {
                videoElement.srcObject = cameraStream;
              }
            }}
            autoPlay
            playsInline
          />
        </Box>
      )}

      {/* AI Interview Text */}
      <Text fontSize="lg" color="white">
        {t('ai_interview')}
      </Text>

      {/* AI Response */}
      <Box p={4} borderWidth="1px" borderRadius="md" bg="gray.700">
        <Text fontSize="md" color="white">
          {aiResponse || t('waiting_for_ai')}
        </Text>
      </Box>

      {/* Recording Button */}
      <ReactMediaRecorder
        audio
        onStop={async (blobUrl, blob) => {
          if (!blob) return;
          setIsRecording(false);
          await handleSendAudio(blob);
        }}
        render={({ startRecording, stopRecording }) => (
          <Button
            colorScheme={isRecording ? 'red' : 'green'}
            onClick={() => {
              if (isRecording) {
                stopRecording();
              } else {
                startRecording();
                setIsRecording(true);
              }
            }}
            isDisabled={isProcessing || isPlayingAudio || !conversationStarted}
          >
            {isRecording ? t('stop_recording') : t('start_recording')}
          </Button>
        )}
      />

      {/* Start/Stop Conversation */}
      {conversationStarted ? (
        <Button
          colorScheme="blue"
          onClick={stopConversation}
          isDisabled={conversationFinished}
        >
          {t('stop_conversation')}
        </Button>
      ) : (
        <Button colorScheme="blue" onClick={startConversation}>
          {t('start_conversation')}
        </Button>
      )}

      {/* Conversation Summary */}
      {conversationFinished && (
        <Text fontSize="md" color="green.300">
          {t('conversation_completed')}{' '}
          <a href={summaryFileUrl} download>
            {t('here')}
          </a>
          .
        </Text>
      )}

      {/* Confirmation Modal */}
      {isOpen && (
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backgroundColor="rgba(0, 0, 0, 0.5)"  // Semi-transparent black background
        zIndex="100"
      >
        <Box
          bg="gray.800"  // Same background color as conversation screen
          p={6}
          borderRadius="md"
          border="1px solid #2d3748"  // Adding a border similar to the conversation box
          maxWidth="400px"
          margin="auto"
          mt="20vh"
        >
          <Text fontSize="lg" mb={4} color="white"> {/* Text color set to white */}
            {t('are_you_sure_stop_conversation')}
          </Text>
          <HStack justifyContent="flex-end">
            <Button variant="ghost" onClick={onClose} colorScheme="gray">
              {t('cancel')}
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                stopConversation();
                onClose();
              }}
            >
              {t('confirm')}
            </Button>
          </HStack>
        </Box>
      </Box>
      )}
    </VStack>
);

  return (
    <ChakraProvider>
      <Flex minH="100vh" align="center" justify="center" bg="gray.900">
        <Box
          bg="gray.800"
          p={8}
          rounded="lg"
          boxShadow="lg"
          maxW="lg"
          w="100%"
        >
          {step === 0 && renderSessionIdStep()}
          {step === 1 && renderLanguageSelectionStep()}
          {step === 2 && renderMediaSettingsStep()}
          {step === 3 && renderConversationStep()}
        </Box>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
